@use '../../styles/colors.scss';

.container {
	display: flex;
	justify-content: center;
	height: 100vh;
	min-height: calc(40vw + 13rem);
	background-color: colors.$mygray;
}

.form-wrapper {
	display: block;
	margin: auto;
	padding: 2.5rem;
	width: calc(8vw + 30rem);
	text-align: center;
	background-color: white;
	border-radius: 10px;
	box-shadow: colors.$myshadow;
}

.form-wrapper {
	input {
		margin-bottom: 1rem;
		padding: 0 1rem;
		width: 100%;
		border: none;
		border-radius: 5px;
		border: 1px solid colors.$mydarkgray;
		line-height: calc(0.5vw + 2.4rem);
		font-size: calc(0.5vw + 1rem);

		&:focus {
			outline: colors.$myblue 2px solid;
		}
	}

	.label {
		margin-bottom: 0.5rem;
		font-family: 'DM Serif Text';
		font-size: calc(0.5vw + 1rem);
		text-align: left;
	}

	.textarea {
		border: 1px solid colors.$mydarkgray;
		width: 100%;
		height: calc(5vw + 5.5rem);
		padding: 1rem;
		margin-bottom: 1rem;
		border-radius: 5px;
		line-height: 1.4rem;
		resize: none;
		font-family: Arial;
		font-size: calc(0.5vw + 1rem);

		&:focus {
			outline: colors.$myblue 2px solid;
		}
	}

	.button {
		padding: 1rem;
		border: none;
		border-radius: 10px;
		font-family: Arial, sans-serif;
		font-weight: 700;
		font-size: calc(0.3vw + 0.8rem);
		color: white;
		background-color: colors.$mygreen;
		border: 2px solid colors.$mygreen;
		cursor: pointer;
		transition: 0.5s;

		&:hover {
			background-color: colors.$mywhite;
			color: colors.$mygreen;
			border: 2px solid colors.$mygreen;
		}
	}

	.error {
		color: red;
		width: 100%;
		font-size: calc(0.3vw + 0.8rem);
		height: calc(0.3vw + 0.8rem);
		text-align: left;
		margin-bottom: 1.5rem;
	}
}
