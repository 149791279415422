@use '../../../../styles/colors.scss';

.container {
	display: block;
	text-align: center;
	min-height: clamp(60rem, calc(40vw + 15rem), 80rem);
	background-image: linear-gradient(180deg, colors.$myblue, white);
	overflow-x: hidden;

	img {
		height: calc(10vw + 15rem);
		pointer-events: none;
	}

	h2 {
		font-family: 'DM Sans', sans-serif;
		font-weight: 700;
		font-size: calc(0.2vw + 1.1rem);
	}
}

.header {
	display: flex;
	justify-content: center;
	margin-bottom: 10rem;

	.header-text {
		width: 100%;
		max-width: calc(20vw + 20rem);

		h1 {
			font-size: calc(0.2vw + 2.3rem);
			margin-bottom: 2rem;
		}
	}
}

.section {
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 10rem auto;
	width: 100%;
	max-width: 1080px;

	.text {
		width: 100%;
		max-width: calc(20vw + 8rem);
		text-align: left;

		h1 {
			font-size: calc(0.2vw + 2rem);
			margin-bottom: 1rem;
		}
	}

	img {
		opacity: 0;
	}
}

.links-container {
	display: flex;
	justify-content: center;
	margin-right: 3rem;

	.course-links {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: calc(1vw + 1rem);
		opacity: 0;

		background-image: url('../../../../assets/window.png');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: fit-content;
		height: fit-content;
		padding: calc(1vw + 3rem) calc(1vw + 2rem);

		img {
			height: calc(5vw + 10rem);
			opacity: 0;
		}

		.link:hover {
			img {
				transform: scale(1.01) !important;
			}
		}
	}
}

.animation {
	animation: fade 1s both;
}

@keyframes fade {
	0% {
		opacity: 0;
		transform: translateY(50px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@media screen and (max-width: 900px) {
	.section {
		display: block;

		img {
			width: calc(10vw + 15rem) !important;
			height: auto;
		}

		.text {
			margin: 3rem auto;
			max-width: calc(20vw + 25rem);
			text-align: center;
		}

		&.sectionB {
			display: flex;
			flex-direction: column;

			.text {
				order: 2;
			}
			img {
				order: 1;
			}
		}
	}

	.links-container {
		margin: 0;

		img {
			height: auto !important;
			width: calc(10vw + 5rem) !important;
		}
	}
}
