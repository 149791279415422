@use '../../../../styles/colors.scss';

.navbar {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 calc(2vw + 1rem);
	position: fixed;
	width: 100%;
	height: clamp(5rem, calc(5vw + 1rem), 7rem);
	z-index: 10;

	.scrollTrans {
		background: black;
	}
}

.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 1080px;
	width: 100%;
}

.logo {
	img {
		height: clamp(1.3rem, calc(1vw + 0.5rem), 1.4rem);
		pointer-events: none;
	}
}

.button-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.button {
	background-color: rgba(255, 255, 255, 0.5);
	border: none;
	border-radius: 10px;
	width: calc(0.5vw + 5.5rem);
	height: calc(0.1vw + 2.7rem);
	font-family: Arial, sans-serif;
	font-weight: 700;
	font-size: calc(0.1vw + 0.9rem);
	cursor: pointer;
	color: black;
	transition: 0.5s;
	margin: 0 auto;

	&:hover {
		background-color: rgba(255, 255, 255, 0.7);
	}

	&.button-transition {
		background-color: rgba(220, 220, 220, 0.5) !important;

		&:hover {
			background-color: rgba(220, 220, 220, 0.3) !important;
		}
	}
}
