.container {
	display: block;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding-top: 25%;
	height: 100%;
	width: 100vw;
	background-color: rgba(204, 87, 93, 0);
}
