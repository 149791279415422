$myblue: #83c3ff;
$mywhite: #fffefc;
$mygray: #f7f8fa;
$mydarkgray: #aeaeae;
$myblack: #454545;
$mygreen: #56e391;
$myred: #ff4a4a;

$myshadow: 0 20px 20px rgba(154, 160, 185, 0.75), 0 15px 40px rgba(166, 173, 201, 0.2);
$smaller-shadow: 0 2.5px 2.5px rgba(154, 160, 185, 0.75), 0 5px 5px rgba(166, 173, 201, 0.2);
$bigger-shadow: 0 5px 5px rgba(154, 160, 185, 1), 0px 5px 50px rgba(166, 173, 201, 0.5);
