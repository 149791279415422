@use '../../../styles/colors.scss';

.toast {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: space-around;
	width: 300px;
	height: 50px;
	padding: 15px;
	border-radius: 5px;
	box-shadow: colors.$myshadow;

	font-family: Arial;
	font-weight: 700;
	cursor: pointer;

	transition: 0.2s;
	animation: fadeIn 3s;

	&:hover {
		opacity: 0.9;
	}

	img {
		height: 20px;
	}
}

.success {
	background-color: #eaffe7;
	.message {
		color: #00e760 !important;
	}
}

.delete {
	background-color: #ffd5d5;
	.message {
		color: colors.$myred !important;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateX(50px);
	}
	20% {
		opacity: 1;
		transform: translateX(0);
	}

	80% {
		opacity: 1;
		transform: translateX(0);
	}

	100% {
		opacity: 0;
		transform: translateX(50px);
	}
}
