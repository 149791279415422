@use '../../styles/colors.scss';
@use '../../styles/fonts.scss';

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;

	.wrapper {
		width: 250px;
		height: 300px;
		background-color: white;
		border: 0.5px solid colors.$mydarkgray;
		border-radius: 10px;
		box-shadow: colors.$smaller-shadow;
		transition: 0.5s;

		&:hover {
			box-shadow: colors.$bigger-shadow;
		}
	}
}

.container {
	font-family: 'DM Serif Text';

	.image-wrapper {
		display: block;
		overflow: hidden;
		height: 50%;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;

		.image {
			max-width: 100%;
		}

		.default-image {
			max-width: 100%;
		}
	}

	.title-wrapper {
		display: -webkit-box;
		height: 95px;
		padding: 10px;
		margin-bottom: 10px;
		font-size: 1.5rem;
		font-weight: 400;
		overflow-x: hidden;
		overflow-y: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	.share-wrapper {
		height: 45px;
		padding: 10px 10px;
		background-color: colors.$mygray;
		border-radius: 0 0 10px 10px;
		font-family: 'DM Serif Text';
		transition: 0.5s;

		div {
			float: left;
		}

		img {
			float: right;
		}

		&:hover {
			background-color: colors.$mydarkgray;
		}
	}
}
