@use '../../styles/colors.scss';

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;

	.wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: calc(2vw + 1rem) calc(2vw + 2rem);
		width: calc(10vw + 25rem);
		border-radius: 10px;
		background-color: white;
		box-shadow: colors.$myshadow;

		h1 {
			margin-bottom: calc(0.5vw + 2rem);
			color: colors.$myblue;
			font-size: calc(0.5vw + 2.5rem);
		}

		h2 {
			margin-bottom: calc(0.5vw + 2rem);
			font-size: calc(0.5vw + 1.5rem);
		}

		.link {
			font-family: 'DM Serif Text';
			font-size: calc(0.5vw + 1rem);
			text-align: center;
			transition: 0.5s;

			&:hover {
				color: colors.$myblue;
			}
		}
	}
}
