@use '../../styles/colors.scss';

.container {
	background-color: colors.$mygray;

	.nav-placeholder {
		height: clamp(5rem, calc(5vw + 1rem), 6.5rem);
	}

	.wrapper {
		padding: 0 calc(7vw + 0.5rem);
		padding-bottom: 5rem;
	}
}

.greeting-container {
	margin: calc(0.5vw + 1.5rem) 0;
	font-size: calc(0.7vw + 1.2rem);
	border-bottom: 2px colors.$mydarkgray solid;
}

.tab-container {
	display: flex;
	justify-content: space-between;
	margin-bottom: calc(1vw + 1rem);

	.tab {
		font-size: calc(0.7vw + 2rem);
		font-family: 'DM Serif Display';
		display: flex;
		justify-content: center;
		align-content: center;
		flex-direction: column;
	}
}

.button-container {
	display: flex;
	align-items: center;

	.create-button {
		padding: 1rem;
		border: none;
		border-radius: 10px;
		font-family: Arial, sans-serif;
		font-weight: 700;
		font-size: calc(0.3vw + 0.8rem);
		color: white;
		background-color: rgba(73, 73, 73, 1);
		cursor: pointer;
		transition: 0.5s;

		&:hover {
			background-color: rgba(73, 73, 73, 0.5);
		}
	}
}

.courses-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
	grid-gap: 2rem;
}

.empty-message {
	font-family: 'DM Sans';
	font-weight: 500;
	font-size: calc(0.3vw + 1.5rem);
	width: 100%;

	.link {
		display: inline-block;
		transition: 0.5s;
		font-family: Arial;
		font-weight: 700;

		&:after {
			content: '';
			display: block;
			height: 3px;
			width: 0%;
			background-color: colors.$myblue;
			transition: 0.5s;
		}

		&:hover:after {
			width: 100%;
		}

		&:hover {
			color: colors.$myblue;
		}
	}
}
