@use '../../styles/colors.scss';

.container {
	display: flex;
	justify-content: center;
	height: 100vh;
	min-height: calc(40vw + 12rem);
	background-color: colors.$mygray;
}

.form-container {
	display: block;
	justify-content: center;
	text-align: center;
	margin: auto;
	padding: 2.5rem;
	width: calc(8vw + 25rem);
	border-radius: 10px;
	background-color: white;
	box-shadow: colors.$myshadow;
}

.title {
	margin-bottom: 1.5rem;
	text-align: center;
	font-size: 50px;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700;
}

.error {
	color: red;
	width: 100%;
	font-size: calc(0.3vw + 0.8rem);
	height: calc(0.3vw + 0.8rem);
	text-align: left;
	margin: 0.5rem 0 2rem;
	padding: 0 0.5rem;
}

.form-container input {
	border: none;
	border-radius: 10px;
	background-color: colors.$mygray;
	margin: 1rem 0;
	padding: 0 1.5rem;
	width: 100%;
	line-height: calc(0.5vw + 2.4rem);
	font-size: calc(0.5vw + 1rem);
}

input:focus {
	outline: colors.$myblue 2px solid;
}

.login {
	padding: 1rem 2rem;
	margin-bottom: 3rem;
	border: none;
	border-radius: 10px;
	font-family: Arial, sans-serif;
	font-weight: 700;
	font-size: calc(0.3vw + 0.8rem);
	color: white;
	background-color: black;
	cursor: pointer;
	transition: 0.5s;

	&:hover {
		background: rgba(0, 0, 0, 0.75);
	}
}

.register {
	padding: 0 0.5rem;
	width: 100%;
	color: colors.$myblack;
	font-size: calc(0.3vw + 0.9rem);
	font-family: Arial;
	font-weight: 700;
	text-align: left;

	.link {
		color: colors.$myblue;
		text-decoration: underline;
		transition: 0.5s;

		&:hover {
			color: colors.$myblack;
		}
	}
}
