@use './styles/colors.scss';
@use './styles/fonts.scss';

* {
	margin: 0;
	padding: 0;
	list-style-type: none;
	text-decoration: none;
	box-sizing: border-box;
	color: black;
	font-synthesis: none !important;
}

html,
body {
	height: 100%;
	width: 100%;
	font-size: 14px;
	background-color: colors.$mygray;

	link {
		color: black;
	}
}
