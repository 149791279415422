@use '../../styles/colors.scss';

.container {
	display: flex;
	justify-content: center;
	height: 100vh;
	min-height: calc(40vw + 13rem);
	background-color: colors.$mygray;
}

.form-wrapper {
	display: block;
	margin: auto;
	padding: 2.5rem 4rem;
	width: calc(8vw + 30rem);
	text-align: center;
	background-color: white;
	border-radius: 10px;
	box-shadow: colors.$myshadow;
}

.title {
	margin-bottom: 1rem;
	text-align: left;
	font-size: calc(0.5vw + 2rem);
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700;

	b {
		color: colors.$myblue;
	}
}

.form-wrapper input {
	border: none;
	border-radius: 10px;
	background-color: colors.$mygray;
	margin: 15px 0;
	padding: 0 15px;
	width: 100%;
	line-height: calc(0.5vw + 2.5rem);
	font-size: calc(0.5vw + 0.95rem);

	&:focus {
		outline: colors.$myblue 2px solid;
	}
}

.name input {
	margin-right: 10px;
	width: calc(50% - 10px);
}

.register {
	padding: 1rem 2rem;
	margin-bottom: 2rem;
	border: none;
	border-radius: 10px;
	font-family: Arial, sans-serif;
	font-weight: 700;
	font-size: calc(0.3vw + 0.8rem);
	color: white;
	background-color: black;
	cursor: pointer;
	transition: 0.5s;

	&:hover {
		background-color: rgba(0, 0, 0, 0.75);
	}
}

.error {
	padding: 0 0.5rem;
	margin-bottom: 1.5rem;
	width: 100%;
	height: calc(0.3vw + 0.8rem);
	font-size: calc(0.3vw + 0.8rem);
	text-align: left;
	color: red;
}

.login {
	padding: 0 0.5rem;
	width: 100%;
	color: colors.$myblack;
	font-size: calc(0.3vw + 0.9rem);
	font-family: Arial;
	font-weight: 700;
	text-align: left;

	.link {
		color: colors.$myblue;
		text-decoration: underline;
		transition: 0.5s;

		&:hover {
			color: colors.$myblack;
		}
	}
}
