.container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
}

.wrapper {
	display: block;
	text-align: center;

	h1 {
		font-size: calc(0.5vw + 1.5rem);
		margin-bottom: 2rem;
	}
}
