@use '../../../../styles/colors.scss';

.navbar {
	position: fixed;
	top: clamp(5rem, calc(5vw + 1rem), 6.5rem);
	padding-bottom: 125px;
	width: 350px;
	height: 100%;
	overflow-y: scroll;
	background-color: colors.$mygray;
	z-index: 10;
}

.navbar::-webkit-scrollbar {
	width: 10px;
}

.navbar::-webkit-scrollbar-track {
	background: colors.$mygray;
	border-radius: 10px;
	margin-block: 5px;
}

.navbar::-webkit-scrollbar-thumb {
	background: colors.$mywhite;
	border-radius: 10px;
	border: 1.5px solid colors.$mygray;
}

.course-header {
	margin: 27.5px 10px;
	border: 1px solid colors.$mydarkgray;
	border-radius: 10px;
	box-shadow: colors.$smaller-shadow;

	.title {
		border-radius: 10px;
		font-size: 1.5rem;
		font-family: 'DM Serif Text';
		font-weight: 400;
		padding: 20px 20px;
		transition: 0.2s;

		&:hover {
			background-color: colors.$myblack;
			color: colors.$mywhite;
		}
	}
}

.navbar ul li {
	font-size: 1.25rem;
	font-family: 'DM Serif Text';
}

.link {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 10px;
	padding: 0px 15px;
	height: 50px;
	width: auto;
	border: 1px solid colors.$mydarkgray;
	border-radius: 10px;
	box-shadow: colors.$smaller-shadow;
	transition: 0.5s;

	&:hover {
		background-color: colors.$myblack;
		.lesson-title {
			color: colors.$mywhite;
		}
	}

	.lesson-title {
		display: block;
		width: 85%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.check-wrapper {
		display: flex;

		img {
			height: 20px;
		}
	}
}

.selected {
	background-color: colors.$myblack;
	color: colors.$mywhite;

	.lesson-title {
		color: colors.$mywhite;
	}
}

.link:hover {
	background-color: colors.$myblack;
	color: colors.$mywhite;
}

.tab {
	display: none;
	position: absolute;
	z-index: 5;
	top: 45vh;
	left: 0;

	button {
		background-color: rgba(0, 0, 0, 0);
		border: none;

		img {
			height: 100px;
			cursor: pointer;
		}
	}
}

@media screen and (max-width: 1080px) {
	.navbar {
		border-right: 1px solid colors.$mydarkgray;
		transform: translateX(-350px);
		transition: 0.5s ease-out;

		&.toggle {
			transform: translateX(0);
		}
	}

	.tab {
		display: block;
		transition: 0.5s ease-out;

		&.toggle {
			transform: translateX(350px);
		}
	}
}

@media screen and (max-width: 450px) {
	.navbar {
		width: 300px;
		transform: translateX(-300px);
	}

	.tab {
		&.toggle {
			transform: translateX(300px);
		}
	}
}
