@use '../../styles/colors.scss';

.container {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.8);
	animation: fade 1s;
}

.modal {
	display: block;
	width: 400px;
	height: 300px;
	padding: 20px;
	border-radius: 5px;
	box-sizing: border-box;
	background-color: colors.$mygray;

	font-family: Arial;
	font-size: 1.5rem;
	font-weight: 700;
	text-align: center;
	cursor: default;

	animation: fadeIn 1s;

	pointer-events: auto;

	img {
		height: 80px;
		margin-bottom: 20px;
	}

	.message {
		margin-bottom: 60px;
		width: 100%;
	}

	.buttons {
		display: flex;
		justify-content: space-around;
		margin-left: 100px;

		button {
			align-items: center;
			width: 100px;
			height: 40px;
			border-radius: 10px;
			background-color: white;
			font-family: Arial, sans-serif;
			font-weight: 700;
			font-size: 1.15rem;
			cursor: pointer;
			transition: 0.2s;

			&.confirm {
				color: colors.$myred;
				border: 2px solid colors.$myred;

				&:hover {
					background-color: colors.$mygray;
				}
			}

			&.cancel {
				color: colors.$mydarkgray;
				border: 2px solid colors.$mydarkgray;

				&:hover {
					background-color: colors.$mygray;
				}
			}

			&:hover {
			}
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateY(-100px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
