@use '../../../../styles/colors.scss';

.container {
	display: flex;
	justify-content: center;
	min-height: clamp(60rem, calc(40vw + 15rem), 80rem);
	background: colors.$myblue;
	overflow-x: hidden;
}

.component-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 1080px;
}

.left-component {
	font-size: calc(0.2vw + 1.5rem);
	cursor: default;
	user-select: none;
	h1 {
		margin: 5rem;
		transition: 0.5s;

		&:hover {
			color: white;
		}
	}
}

.right-component {
	img {
		height: calc(10vw + 30rem);
		pointer-events: none;
	}
}

.dynamic {
	font-family: 'DM Serif Display', serif;
	color: black;
}

.dynamic {
	animation: fade 1s both;
	-moz-animation: fade 1s both;
	-webkit-animation: fade 1s both;
}

.dynamic:nth-child(2) {
	animation: fade 1.5s both;
	-moz-animation: fade 1.5s both;
	-webkit-animation: fade 1.5s both;
}

.dynamic:nth-child(3) {
	animation: fade 2s both;
	-moz-animation: fade 2s both;
	-webkit-animation: fade 2s both;
}

.animation {
	animation: fade 2.5s both;
	-moz-animation: fade 2.5s both;
	-webkit-animation: fade 2.5s both;
}

@keyframes fade {
	0% {
		opacity: 0;
		transform: translateY(50px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@-moz-keyframes fade {
	0% {
		opacity: 0;
		transform: translateY(50px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@-webkit-keyframes fade {
	0% {
		opacity: 0;
		-webkit-transform: translateY(50px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@media screen and (max-width: 599px) {
	.right-component {
		display: none;
	}
	.component-container {
		justify-content: center;
	}
}
