@use '../../../../../styles/colors.scss';

.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem calc(0.5vw + 0.85rem);
	background-color: white;

	h1 {
		font-family: Arial, sans-serif;
		font-size: calc(0.25vw + 0.9rem);
		color: colors.$myblack;
	}

	.links {
		display: flex;

		& > * {
			font-family: Arial, sans-serif;
			font-size: calc(0.25vw + 0.7rem);
			font-weight: 700;
			color: colors.$myblack;
			margin-left: 0.5rem;
			transition: 0.5s;

			&:hover {
				color: colors.$myblue;
			}
		}
	}
}

@media screen and (max-width: 750px) {
	.container {
		h1 {
			font-size: calc(1vw + 0.3rem);
		}

		.links {
			& > * {
				font-size: calc(1vw + 0.2rem);
			}
		}
	}
}
