@use '../../styles/colors.scss';

.wrapper {
	width: 100vw;
}

.workspace-container {
	display: flex;
}

.right-component {
	position: fixed;
	width: calc(100% - 350px);
	height: 100%;
	right: 0;
	margin-top: clamp(5rem, calc(5vw + 1rem), 6.5rem);
	overflow-y: scroll;
	padding: 0 calc(0.5vw + 1rem);
	padding-bottom: 15rem;
	background-color: colors.$mygray;
}

.editor-viewport {
}

.viewport-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 1rem 0 2rem;
	width: 100%;

	.lesson-title {
		width: calc(100% - 350px);

		h1 {
			font-size: clamp(1.6rem, calc(0.5vw + 1.5rem), 2.25rem);
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.header-buttons {
		display: flex;
		gap: 1rem;
	}
}

.button {
	padding: clamp(0.8rem, calc(0.05vw + 0.75rem), 0.8rem) calc(0.05vw + 1.5rem);
	background-color: rgba(73, 73, 73, 1);
	border-radius: 10px;
	font-family: Arial, sans-serif;
	font-weight: 700;
	font-size: clamp(0.85rem, calc(0.3vw + 0.8rem), 1.1rem);
	cursor: pointer;
	color: white;
	transition: 0.2s;
	align-items: center;
}

.button.add {
	padding: 0.75rem 1.5rem;
	background-color: colors.$mygreen;
	border: 2px solid colors.$mygreen;

	&:hover {
		background-color: colors.$mywhite;
		color: colors.$mygreen;
		border: 2px solid colors.$mygreen;
	}
}

.button.save-lesson {
	display: flex;
	gap: 0.25rem;
	background-color: white;
	border: 2px solid colors.$myblue;

	span {
		color: colors.$myblue;
	}

	&:hover {
		background-color: colors.$mygray;
	}
}

.button.delete {
	display: flex;
	gap: 0.25rem;
	background-color: white;
	border: 2px solid colors.$myred;

	span {
		color: colors.$myred;
	}

	&:hover {
		background-color: colors.$mygray;
	}
}

.preview-container {
	position: fixed;
	bottom: 1rem;
	right: 2rem;
	z-index: 10;

	.preview {
		border: none;
		&:hover {
			background-color: colors.$mydarkgray;
		}
	}
}

.input {
	width: 100%;
	border: 1px solid colors.$mydarkgray;
	border-radius: 5px;
	margin: 10px 0;
	padding: 0 20px;
	line-height: 3rem;
	font-size: 20px;

	&:focus {
		outline: colors.$myblue 2px solid;
	}
}

.course-editor {
	label {
		font-family: 'DM Serif Text';
		font-size: calc(0.5vw + 1rem);
	}

	.image-wrapper {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-top: 20px;
		border-radius: 10px;
		background-color: colors.$mywhite;
		img {
			max-width: 500px;
			max-height: 500px;
			padding: 0 auto;
		}
	}

	textarea {
		height: 400px;
		font-family: Arial;
		resize: none;
	}
}

.wysiwyg-wrapper {
	border: 1px solid colors.$mydarkgray;
	border-radius: 5px;
	min-height: 350px;

	.wysiwyg-editor {
		padding: 0 20px;
		font-size: calc(0.5vw + 0.8rem);

		li {
			list-style-type: disc;
		}

		img {
			width: 50% !important;
			transform: translateX(50%);
		}

		pre {
			margin: 0.5rem 0;
			padding: 0.5rem;
			background-color: colors.$myblack;

			& > div {
				margin: 0;
			}

			* {
				color: colors.$mywhite;
			}
		}
	}
}

.lesson-form {
	padding: 2rem calc(0.1vw + 1.4rem);
	border: 1px solid colors.$myblack;
	border-radius: 10px;
	background-color: white;

	label {
		font-size: calc(0.5vw + 1rem);
		margin-bottom: 1rem;
	}
}

@media screen and (max-width: 1080px) {
	.right-component {
		padding: 0 calc(7vw + 0.5rem) 15rem;
		width: 100%;
		.header-buttons {
			.button {
				padding: 0.5rem 1rem;
			}
		}

		.preview-container {
			right: calc(7vw + 0.5rem);
		}
	}
}

@media screen and (max-width: 650px) {
	.right-component {
		.lesson-title {
			width: calc(100% - 150px) !important;
		}

		.button.save-lesson {
			span {
				display: none;
			}
		}

		.button.delete {
			span {
				display: none;
			}
		}
	}
}
