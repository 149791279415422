@use '../../styles/colors.scss';

.container {
	position: fixed;
	width: calc(100% - 350px);
	height: 100%;
	right: 0;
	margin-top: clamp(5rem, calc(5vw + 1rem), 6.5rem);
	overflow-y: scroll;
	padding: 0 calc(0.5vw + 1rem);
	padding-bottom: 15rem;
	background-color: colors.$mygray;
}

.wrapper {
	margin-top: 2rem;
	padding: 50px;
	border-radius: 10px;
	box-shadow: colors.$smaller-shadow;
	background-color: colors.$mywhite;
}

.course-title {
	margin-bottom: 25px;
	text-align: center;
	font-family: 'DM Serif Text';
	font-size: clamp(2.25rem, calc(1.5vw + 1.5rem), 4rem);
	font-weight: 400;
}

.course-author {
	text-align: center;
	margin-bottom: 25px;
	font-size: clamp(1.7rem, calc(1.5vw + 0.25rem), 2rem);

	b {
		color: colors.$myblue;
		transition: 0.5s;

		&:hover {
			color: black;
		}
	}
}

.course-image {
	display: flex;
	justify-content: center;
	margin-bottom: 50px;

	img {
		max-width: 50%;
	}
}

.course-description {
	font-family: 'DM Sans';
	font-weight: 500;
	font-size: clamp(1rem, calc(0.5vw + 1rem), 2.5rem);
}

.lesson-title {
	margin-bottom: 40px;
	border-bottom: 1px solid colors.$mydarkgray;
	text-align: left;
	font-family: 'DM Serif Text';
	font-size: clamp(2rem, calc(0.5vw + 2rem), 3.5rem);
	font-weight: 400;
}

.html-content {
	font-family: 'DM Sans';
	font-weight: 500;
	font-size: clamp(1rem, calc(0.5vw + 1rem), 2rem);
	line-height: 1.25rem;
	white-space: pre-wrap;

	li {
		padding-left: 1rem;
		list-style-type: disc;
		list-style-position: inside;
	}

	img {
		width: 50% !important;
		transform: translateX(50%);
		margin-bottom: 2rem;
	}

	p {
		line-height: 2.25rem;
	}

	code {
		background-color: colors.$mygray;
	}

	pre {
		padding: 0.5rem;
		border-radius: 5px;
		line-height: 1.75rem;
		background-color: colors.$myblack;
		color: colors.$mywhite;
		white-space: pre-wrap;

		* {
			color: colors.$mywhite;
		}
	}
}

.next-button {
	display: flex;
	justify-content: center;
	margin-top: 5rem;

	button {
		background-color: colors.$mygreen;
		border: none;
		border-radius: 10px;
		padding: calc(0.25vw + 0.75rem) calc(0.25vw + 1rem);
		font-family: Arial, sans-serif;
		font-weight: 700;
		font-size: calc(0.1vw + 1rem);
		cursor: pointer;
		color: white;
		transition: 0.2s;
		margin: 10px 0;
		align-items: center;

		&:hover {
			background-color: rgba(86, 227, 145, 0.8);
		}
	}
}

@media screen and (max-width: 1080px) {
	.container {
		padding: 0 calc(7vw + 0.5rem) 15rem;
		width: 100%;
	}
}
