@use '../../styles/colors.scss';

.navbar {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 calc(7vw + 0.5rem);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: clamp(5rem, calc(5vw + 1rem), 6.5rem);
	z-index: 10;
	background-color: colors.$myblue;
}

.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.logo-container {
	img {
		height: clamp(1.3rem, calc(1vw + 0.5rem), 1.4rem);
	}
}

.logout-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.logout {
	background: rgba(0, 0, 0, 0);
	border: none;
	border-radius: 10px;
	width: calc(0.5vw + 5.5rem);
	height: calc(0.1vw + 2.7rem);
	font-family: Arial, sans-serif;
	font-weight: 700;
	font-size: calc(0.1vw + 1rem);
	cursor: pointer;
	color: white;
	transition: 0.2s;

	&:hover {
		border: 2px white solid;
	}
}
